import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ImageGallery from 'react-image-gallery'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import ShareBlock from '../components/ShareBlock'
import 'react-image-gallery/styles/scss/image-gallery.scss'
import PageHeader from '../components/general/PageHeader'

export const GalleryPageTemplate = (props) => {
  const { title, description, featuredImage, images = [], location } = props

  return (
    <>
      <Helmet titleTemplate="%s | مركز الدراسات السودانية">
        <title>{`${title}`}</title>
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="ar_TN" />
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:image" content={featuredImage} />
        <meta name="twitter:image" content={featuredImage} />
      </Helmet>
      <PageHeader className="hassan_3">{title}</PageHeader>

      <div className="has-text-justified">
        <div className="container">
          <div className="column is-12" style={{ 'margin-bottom': '70px' }}>
            <p>{description}</p>
          </div>

          <div className="column is-12">
            <ImageGallery
              isRTL
              items={images.map((image) => ({
                original: image.url,
                thumbnail: image.url,
              }))}
            />
            <ShareBlock
              url={location.href}
              slug={description}
              text={`${title} | @SudanSSC `}
            />
          </div>
        </div>
      </div>
    </>
  )
}

GalleryPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  featuredImage: PropTypes.object,
  images: PropTypes.array,
  location: PropTypes.object,
}

const GalleryPage = ({ data, location }) => {
  const { strapiArticle: post } = data

  return (
    <Layout>
      <GalleryPageTemplate
        title={post.title}
        images={post.images}
        featuredImage={post.image || {}}
        description={post.body}
        location={location}
      />
    </Layout>
  )
}

GalleryPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
}

export default GalleryPage

export const galleryPageQuery = graphql`
  query GalleryByID($id: String!) {
    strapiArticle(id: { eq: $id }) {
      id
      strapiId
      body
      excerpt
      publishDate(formatString: "MMMM DD, YYYY")
      strapiId
      slug
      title
      updated_at
      created_at
      image {
        url
        formats {
          small {
            url
          }
        }
      }
      images {
        url
      }
    }
  }
`
