import React from 'react'
import { ShareButtonOutline, ShareBlock } from 'react-custom-share'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import { FaTwitter, FaFacebook, FaEnvelope } from 'react-icons/fa'

const ShareBlockIcons = ({ text, slug, containerStyle, blockTitle, url }) => {
  const blocks = [
    { type: 'facebook', icon: <FaFacebook /> },
    { type: 'twitter', icon: <FaTwitter /> },
    {
      type: 'email',
      icon: <FaEnvelope />,
    },
  ]
  const share = (ev) => {
    trackCustomEvent({
      category: 'Share',
      action: slug,
    })
  }
  const title = blockTitle || 'شارك هذا الموضوع:'
  const style = containerStyle || 'has-text-link'

  return (
    <div className={style}>
      <p className="has-text-link">{title}</p>
      {blocks.map(({ type, icon }) => (
        <ShareButtonOutline
          network={type}
          className={`fa-lg is-inline-block`}
          text={text}
          data-href={type}
          url={url}
        >
          <span className={`icon ${type}-icon`}>{icon}</span>
        </ShareButtonOutline>
      ))}
    </div>
  )
}

export default ShareBlockIcons
